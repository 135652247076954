import React from 'react';

import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Session from './pages/session';
import './common.scss';
import Lobby from './pages/lobby';

const App: React.FC = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Lobby />} />
				<Route path="/session" element={<Session />} />
			</Routes>
		</BrowserRouter>
	);
};

export default App;
