import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Joystick } from 'react-joystick-component';
import { IJoystickUpdateEvent } from 'react-joystick-component/build/lib/Joystick';
import { NavController } from '../useNavController';
import useHover from 'utils/useHover';
import { Size } from 'types';

class PropsFromParent {
	size: Size;
	navController: Pick<NavController, 'onNavCommand'>;
	handleHovered: Function;
	handleEnabled: Function;
	className?: string;
}
const DISABLED_OPACITY = 0;
const MOVING_OPACITY = 0.25;
const STOPPED_OPACITY = 1;

const JoystickContainer: React.FC<PropsFromParent> = ({
	size,
	navController,
	handleEnabled,
	handleHovered,
	className,
}) => {
	const joystickRef = useRef<any>(true);
	const [opacity, setOpacity] = useState<number>(0);
	const [diameter, setDiameter] = useState<number>(75);
	const [linear, setLinear] = useState<number>(0);
	const [angular, setAngular] = useState<number>(0);
	const [isEnabled, setIsEnabled] = useState<boolean>(false);
	const [containerRef, isHovered] = useHover<HTMLDivElement>();

	useEffect(() => {
		setOpacity(isEnabled || isHovered ? STOPPED_OPACITY : DISABLED_OPACITY);
	}, [isHovered, isEnabled]);

	useEffect(() => {
		handleHovered(isHovered);
	}, [isHovered]);

	useEffect(() => {
		handleEnabled(isEnabled);
	}, [isEnabled]);

	useEffect(() => {
		let interval: ReturnType<typeof setInterval>;
		let stopRequestCounter: number = 0;
		interval = setInterval(function () {
			navController.onNavCommand({
				linear: linear > 0 && isHovered ? linear * 0.66 : linear,
				angular,
			});
			if (angular === 0 && linear === 0)
				if (++stopRequestCounter === 5) {
					clearInterval(interval);
				}
		}, 100);
		return () => clearInterval(interval);
	}, [linear, angular, isHovered]);

	const handleMove = (event: IJoystickUpdateEvent) => {
		setOpacity(MOVING_OPACITY);
		setDiameter(150);
		setIsEnabled(true);
		if (event && event.x && event.y) {
			let x = (-2 * event.x) / diameter;
			let y = (2 * event.y) / diameter;

			// we choose a dead zone for both linear and angular
			// the DEAD ZONE is between the values :-0.25 to -0.25
			if (Math.abs(x) <= 0.25) x = 0;
			if (Math.abs(y) <= 0.25) y = 0;

			setLinear(y);
			setAngular(x);
		}
	};

	const handleStop = () => {
		setDiameter(75);
		setIsEnabled(false);
		setLinear(0);
		setAngular(0);
	};

	// Dynamic Styles
	useEffect(() => {
		// set z-index value to display the joystick above the navigation video
		if (joystickRef.current) {
			joystickRef.current._stickRef.current.style.zIndex = 1;
			joystickRef.current._baseRef.current.style.zIndex = 1;
		}
	}, []);

	useEffect(() => {
		const instance = joystickRef.current;
		const onMouseOver = () => {
			setOpacity(MOVING_OPACITY);
			setDiameter(150);
		};
		const onMouseOut = () => {
			setOpacity(STOPPED_OPACITY);
			setDiameter(75);
		};

		if (instance) {
			instance._baseRef.current.addEventListener('mouseover', onMouseOver);
			instance._baseRef.current.addEventListener('mouseout', onMouseOut);
		}
	}, [joystickRef.current]);

	const stickColor = useMemo(
		() =>
			`radial-gradient(circle at 50% 50%, rgba(70,70,70,${opacity}), rgba(70,70,70,${opacity}), rgba(5,5,5,${opacity}))`,
		[opacity]
	);

	const containerStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 4,
		...size,
	};

	return (
		<div ref={containerRef as any} style={containerStyle} className={className}>
			<Joystick
				ref={joystickRef}
				size={diameter}
				baseColor="transparent"
				stickColor={stickColor}
				move={handleMove}
				stop={handleStop}
				throttle={20}
			/>
		</div>
	);
};

export default JoystickContainer;
