import { UPDATE_MICROPHONE, UPDATE_SPEAKER, UPDATE_CAMERA } from 'actions/types';

const initialState = {
	settings: {
		camera: {
			id: '',
			value: '',
		},
		microphone: {
			microphoneLevel: '',
			id: '',
			name: '',
		},
		speakers: {
			speakerLevel: '',
			id: '',
			name: '',
		},
	},
};

export default (state = initialState, action: Record<string, any>) => {
	switch (action.type) {
		case UPDATE_MICROPHONE:
			return {
				...state,
				settings: {
					...state.settings,
					microphone: action.payload.microphone,
				},
			};
		case UPDATE_SPEAKER:
			return {
				...state,
				settings: {
					...state.settings,
					speakers: action.payload.speakers,
				},
			};
		case UPDATE_CAMERA:
			return {
				...state,
				settings: {
					...state.settings,
					camera: action.payload.camera,
				},
			};
		default:
			return state;
	}
};
