import { RTCInboundRtpStreamStatsEmitter } from '../rtcStats';

const STATISTICS_SAMPLING_PERIOD_MS = 2000;

/** Detects and reports when the video(s) associated with an RTCRtpReceiver stalls and is no longer playing */
export default class RTCVideoStallDetector {
	private statsEmitter: RTCInboundRtpStreamStatsEmitter | null = null;

	private timeoutId: ReturnType<typeof setTimeout> | null = null;
	private statsListenerId: string | null = null;

	/**
	 * @throws If non-null selector is not of kind `video`
	 */
	constructor(
		/** Source of the remote video which has to be checked for stalls */
		rtpReceiver: RTCRtpReceiver | null = null,
		/**
		 * MediaStreamTrack of video kind, that should be checked for stalls.
		 * If not null, only this will determine whether or not we trigger the callback.
		 */
		selector: MediaStreamTrack | null = null,
		private config: {
			/** Time (in milliseconds) after to set flag, once it is detected that the video has stalled */
			timeoutMs: number;
			initialTimeoutMs: number;
		} = { timeoutMs: 10 * 1000, initialTimeoutMs: 20 * 1000 }
	) {
		this.setup(rtpReceiver, selector);
	}

	// private isVideoStalledEventFired = false;
	private isTimeoutScheduled = () => this.timeoutId !== null;

	/** @throws if non-null selector is not of kind `video` */
	private setup = (rtpReceiver: RTCRtpReceiver | null, selector: MediaStreamTrack | null) => {
		this.cleanup();

		if (selector !== null && selector.kind !== 'video') {
			throw new Error(`selector is not of kind 'video'`);
		}

		this.statsEmitter = rtpReceiver
			? new RTCInboundRtpStreamStatsEmitter(rtpReceiver, selector, STATISTICS_SAMPLING_PERIOD_MS)
			: null;

		if (this.statsEmitter === null) return;

		/** True if at least one decoded frame has been recorded */
		let isFirstFrameDecoded = false;

		this.statsListenerId = this.statsEmitter?.addEventListener(
			'framesDecoded',
			(framesDecoded: number) => {
				isFirstFrameDecoded = isFirstFrameDecoded || framesDecoded > 0;

				if (framesDecoded === 0 && !this.isTimeoutScheduled()) {
					const onTimeoutReached = () => {
						this.timeoutId = null;
						this.onRTCVideoStalled?.();
					};

					const timeoutMs = isFirstFrameDecoded
						? this.config.timeoutMs
						: this.config.initialTimeoutMs;

					this.timeoutId = setTimeout(onTimeoutReached, timeoutMs);
				} else if (framesDecoded > 0 && this.isTimeoutScheduled()) {
					clearTimeout(this.timeoutId!);
					this.timeoutId = null;
				}
			}
		);
	};

	private cleanup = () => {
		if (this.timeoutId !== null) {
			clearTimeout(this.timeoutId);
			this.timeoutId = null;
		}

		if (this.statsListenerId !== null) {
			this.statsEmitter?.removeListener('framesDecoded', this.statsListenerId);
			this.statsListenerId = null;
		}

		if (this.statsEmitter !== null) {
			this.statsEmitter.stop();
			this.statsEmitter = null;
		}
	};

	private isStarted = false;
	public start = () => {
		this.isStarted = true;
		this.statsEmitter?.start();
	};

	public stop = () => {
		this.cleanup();
		this.isStarted = false;
	};

	public setVideoSource = (
		rtpReceiver: RTCRtpReceiver | null,
		selector: MediaStreamTrack | null
	) => {
		this.setup(rtpReceiver, selector);

		if (this.isStarted) {
			this.statsEmitter?.start();
		}
	};

	/** Callback for when is stalled for a period of `initialTimeoutMs` or `timeoutMs` */
	public onRTCVideoStalled: (() => void) | null = null;
}
