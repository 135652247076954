import React, { useEffect, useState, useCallback, useLayoutEffect, useMemo } from 'react';
import LocalVideo, { LOCAL_MEDIA_CONSTRAINTS, useLocalMedia } from './videos/localVideo';
import RemotePrimaryCamVideo from './videos/remoteVideo';
import './index.scss';
import { connect } from 'react-redux';
import { setParameter } from 'actions/setParam';
import { SET_DATA_CHANNEL, SET_FULL_SCREEN_STATUS, SET_ROBOT_INFO } from 'actions/types';

import PauseOrEndSessionOverlay from './overlays/sessionEndPause';
import { ConnectedProps } from 'react-redux';
import { AppRootState } from 'reducers';
import RobotUnavailableOverlay from './overlays/failedInitPeerConnection';
import SessionNetworkFailureOverlay from './overlays/failedSessionPeerConnection';
import useCallerPeerConnection from './peerConnection/useCallerPeerConnection';
import { PeerConnectionEndReasonCode } from './peerConnection/useCallerPeerConnection/peerConnection';
import ImpairedDrivingIndicator from './indicators/drivingImpairment';
import useNavController from './navigation/useNavController';
import KeyboardNavInput from './navigation/keyboard';
import MediaAccessErrorOverlay from './overlays/mediaDevicesAccessDenied';
import AutoDockingInput from './navigation/autoDocking';
import NavViewWithSessionOptions from './navigation/view';
import { IActiveNavInput, RobotPrimaryCamera, RtpReceiverID, LocalSessionInfo } from 'types';
import ActiveNavigationInputIndicator from './indicators/activeNavigationInput';
import useSessionOverlay from './overlays/useSessionOverlay';
import RobotName from 'components/robotName';
import useSignalingClient from './peerConnection/signaling';
import { useSearchParams } from 'react-router-dom';
import SessionID from 'components/sessionID';
import RetryingSessionOverlay from './overlays/retryingSession';

const NO_REMOTE_VIDEO_TIMEOUT = 60 * 1000;
const DEFAULT_ZOOM_CAM_CROPPING = { top: 0, bottom: 0, left: 0, right: 0 };

const reduxConnector = connect(
	(state: AppRootState) => ({
		navSpeed: state.sessionState.navSpeed,
		localVoiceVolume: state.sessionState.localVoiceVolume,
	}),
	{ setParameter }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;

const Session: React.FC<PropsFromRedux> = ({ navSpeed, setParameter, localVoiceVolume }) => {
	const [searchParams] = useSearchParams();

	const sessionInfo = useMemo<LocalSessionInfo>(() => {
		const decodedSessionInfo = decodeURIComponent(atob(searchParams.get('sessionInfo') as string));
		return JSON.parse(decodedSessionInfo);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const closeSession = useCallback(async () => {
		await signalingClient.close();
		window.close();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setParameter('robot', SET_ROBOT_INFO, sessionInfo.robot);
		document.title = `GoBe - ${sessionInfo.robot.name}`;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Starting : Navigation Input management logic
	const [currNavInput, setCurrNavInput] = useState<IActiveNavInput | null>(null);
	const buildOnNavInputFocusChanged = (
		forNavInput: IActiveNavInput,
		setState: (callback: (curr: IActiveNavInput | null) => IActiveNavInput | null) => void
	) => {
		return (isNavInputFocused: boolean) => {
			setState((currState) => {
				if (isNavInputFocused) return forNavInput;
				else {
					// If the nav-input identified by `forNavInput` is no longer focused,
					// 	 then we clear/reset state only if it was the one previously focused
					if (currState === forNavInput) return null;
					return currState;
				}
			});
		};
	};
	const onKeyboardNavInputFocusChanged = buildOnNavInputFocusChanged('keyboard', setCurrNavInput);
	const onJoystickActivationChanged = buildOnNavInputFocusChanged('joystick', setCurrNavInput);
	const onAutoDockingActiveChanged = buildOnNavInputFocusChanged('auto-docking', setCurrNavInput);

	// End : Navigation Input management logic

	const [hasPrimaryVideoStartedPlaying, setHasPrimaryVideoStartedPlaying] = useState(false);

	const { currentOverlay, showOverlay, hideOverlay } = useSessionOverlay();

	const [navDataChannel, setNavDataChannel] = useState<RTCDataChannel | undefined>();

	const onDataChannel = useCallback(
		(dataChannel: RTCDataChannel) => {
			if (dataChannel.label === 'nav-datachannel') {
				(window as any).datachannel = dataChannel;
				setNavDataChannel(dataChannel);
			} else {
				console.log('OTHER-DATACHANNEL created');
				setParameter('dataChannel1', SET_DATA_CHANNEL, dataChannel);
				dataChannel.send(`VOL ${localVoiceVolume}`);
			}
		},
		[localVoiceVolume, setParameter]
	);

	const onPeerConnectionStarted = useCallback(() => {
		// TODO: Add some logic for this...
		console.debug('session.Component PeerConnection started');
	}, []);

	const onPeerConnectionEnded = useCallback(
		(reason: PeerConnectionEndReasonCode) => {
			function assertUnreachable(x: never): never {
				throw new Error(`Unhandled reason-code: ${x} in onPeerConnectionEnded()`);
			}

			// todo: Show a different overlays for the retry failure cases

			switch (reason) {
				case 'LOCAL_HANGUP':
				case 'PAUSED_STATE_TIMED_OUT':
					closeSession();
					return;
				// todo: // show an error overlay instead of network failure, when the remote peer hangs up
				case 'PEER_HANGUP': // yeah, our peer is a robot! - this might be an error
				case 'FAILED_STATE_TIMED_OUT':
				case 'RETRY_FAILED':
				case 'RETRY_TIMEOUT':
				case 'ERROR':
					showOverlay('SessionNetworkFailure');
					return;
				case 'CLEANUP':
					return;
				default:
					// this is a type-safe way to ensure that all cases
					//  	of the PeerConnectionEndReasonCode are handled in this switch
					return assertUnreachable(reason);
			}
		},
		[closeSession, showOverlay]
	);

	const signalingClient = useSignalingClient(sessionInfo);

	// setup the controls for the peer connection
	const {
		endPeerConnection,
		startPeerConnection,
		pausePeerConnection,
		unpausePeerConnection,
		togglePrimaryCamera,
		primaryCameraState,
		primaryMediaStream,
		primaryRTPTransceiver,
		navMediaStream,
		navRTPTransceiver,
		capabilities,
		robotStatus,
		sessionState,
	} = useCallerPeerConnection({
		signalingClient,
		onDataChannel,
		onStarted: onPeerConnectionStarted,
		onEnded: onPeerConnectionEnded,
	});

	const primaryCamerasConfig = useMemo((): React.ComponentProps<
		typeof RemotePrimaryCamVideo
	>['cameraConfigs'] => {
		return {
			[RobotPrimaryCamera.WIDE_CAM]: {
				rotationDegrees: capabilities.wide_camera_rotation,
				crop: capabilities.wide_camera_crop,
			},
			[RobotPrimaryCamera.ZOOM_CAM]: {
				rotationDegrees: capabilities.zoom_camera_rotation,
				crop: DEFAULT_ZOOM_CAM_CROPPING,
			},
		};
	}, [capabilities]);

	const [isSessionInitializing, setIsSessionInitializing] = useState(true);
	const isSessionStarting =
		sessionState === 'NotInitialized' ||
		(sessionState === 'InProgress' && !hasPrimaryVideoStartedPlaying);
	const isSessionRetrying = sessionState === 'Retrying';
	const isSessionPaused = sessionState === 'Paused';

	useEffect(() => {
		if (isSessionRetrying || isSessionStarting) {
			showOverlay('SessionRetrying');
		} else if (!isSessionPaused) {
			hideOverlay();
		}
	}, [hideOverlay, isSessionRetrying, isSessionStarting, isSessionPaused, showOverlay]);

	const { media: localMedia, error: localMediaAccessError } = useLocalMedia(
		LOCAL_MEDIA_CONSTRAINTS,
		sessionInfo.devices ?? null
	);
	useEffect(() => {
		if (localMediaAccessError) {
			showOverlay('LocalMediaError');
		}
	}, [localMediaAccessError, showOverlay]);

	// Now start the peer connection
	useEffect(() => {
		if (!localMedia?.stream) return;

		startPeerConnection(localMedia.stream);
	}, [startPeerConnection, localMedia?.stream]);

	/** True if the user can see at least a frame of the video,
	 * and the video is not obscured by any fullscreen-overlays */
	const isVideoVisible = hasPrimaryVideoStartedPlaying && currentOverlay === null;

	const videoRtpReceivers = useMemo(() => {
		return {
			primaryCam: primaryRTPTransceiver?.receiver,
			navCam: navRTPTransceiver?.receiver,
		} as Partial<Record<RtpReceiverID, RTCRtpReceiver>>;
	}, [navRTPTransceiver, primaryRTPTransceiver]);
	const { navController, isNavigationInProgress, isDrivingImpaired, penalty } = useNavController({
		speed: navSpeed,
		isPeerConnectionPaused: isSessionPaused,
		rtpReceivers: videoRtpReceivers,
		datachannel: navDataChannel,
		isVideoVisible,
	});

	const onClickUnpauseSession = () => {
		unpausePeerConnection();
		hideOverlay();
	};

	// Starting : Fullscreen status management logic
	useEffect(() => {
		// add the fullscreen event handler
		const fullScreenChangeHandler = () => {
			if (document.fullscreenElement) {
				setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, true);
			} else {
				setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, false);
			}
		};
		document.addEventListener('fullscreenchange', fullScreenChangeHandler);

		return () => {
			document.removeEventListener('fullscreenchange', fullScreenChangeHandler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// End : Fullscreen status management logic

	useLayoutEffect(() => {
		if (hasPrimaryVideoStartedPlaying) return;
		const primaryCamVideoTimeoutID = setTimeout(
			() => showOverlay('NoRemoteVideo'),
			NO_REMOTE_VIDEO_TIMEOUT
		);
		return () => clearTimeout(primaryCamVideoTimeoutID);
	}, [hasPrimaryVideoStartedPlaying, showOverlay]);
	const onPrimaryVideoPlaybackToggle = (value: boolean) => {
		setHasPrimaryVideoStartedPlaying(value);
		if (value && !isSessionPaused) {
			setIsSessionInitializing(false);
			hideOverlay();
		}
	};

	const isKeyboardNavInputEnabled =
		currentOverlay === null &&
		(currNavInput === 'keyboard' || currNavInput == null) &&
		primaryCameraState.currentPrimaryCamera === 'wide_cam';

	const isJoystickControlSupportedByRobot = capabilities.mouse_control_with_joystick;

	const renderNavViewWithSessionOptions = (args: { isJoystickMounted: boolean }) => {
		return (
			<NavViewWithSessionOptions
				// * SessionOptions props
				onClickHangUp={() => showOverlay('EndOrPauseSessionConfirmation')}
				togglePrimaryCamera={togglePrimaryCamera}
				primaryCameraState={primaryCameraState}
				isSuperZoom1Enabled={capabilities.super_zoom_1}
				localStream={localMedia?.stream ?? null}
				hasPrimaryVideoStartedPlaying={hasPrimaryVideoStartedPlaying}
				// * NavigationVideo props
				mediaStream={navMediaStream}
				navController={navController}
				handleJoystickEnabled={onJoystickActivationChanged}
				isJoystickMounted={args.isJoystickMounted}
				isDrivingAllowed={currNavInput === 'joystick' || currNavInput === 'keyboard'}
				isDrivingImpaired={isDrivingImpaired}
				penalty={penalty}
				// * extra props
				navCameraRotation={capabilities.nav_camera_rotation}
				isNavigating={isNavigationInProgress}
				sessionState={sessionState}
			/>
		);
	};

	return (
		<div
			className="Session"
			id="Session"
			data-session-id={signalingClient.sessionInfo.uuid}
			// FIXME: We must remove any sensitive data from sessionInfo, before adding it as a data-attribute
			// data-session-info={signalingClient.sessionInfo}
		>
			<PauseOrEndSessionOverlay
				isVisible={currentOverlay === 'EndOrPauseSessionConfirmation'}
				isSessionPaused={isSessionPaused}
				onClickResumeSession={onClickUnpauseSession}
				onClickEndSession={endPeerConnection}
				onClickPauseSession={pausePeerConnection}
				onClickCancel={hideOverlay}
			/>

			<RetryingSessionOverlay
				isVisible={currentOverlay === 'SessionRetrying'}
				isSessionStarting={isSessionStarting}
				isSessionInitializing={isSessionInitializing}
				robot={sessionInfo.robot}
				onClickEndSession={endPeerConnection}
			/>

			<RobotUnavailableOverlay
				isVisible={currentOverlay === 'UnavailableRobot' || currentOverlay === 'NoRemoteVideo'}
				closeSession={closeSession}
				onClickTryAgain={closeSession} // TODO: Implement this, and allow user to call robot again
			/>
			<SessionNetworkFailureOverlay
				isVisible={currentOverlay === 'SessionNetworkFailure'}
				closeSession={closeSession}
				robotName={sessionInfo.robot.name}
			/>

			<MediaAccessErrorOverlay
				isVisible={currentOverlay === 'LocalMediaError'}
				onEndSession={endPeerConnection}
				robotName={sessionInfo.robot.name}
				error={localMediaAccessError}
			/>

			<LocalVideo
				robotStatus={robotStatus}
				startWideCameraStats={() => undefined}
				stopWideCameraStats={() => undefined}
				wideCameraStats=""
				isGreyedOut={false}
				isPaused={false}
				shouldShowLoadingIndicator={!hasPrimaryVideoStartedPlaying || isSessionRetrying}
				media={localMedia}
			/>

			<AutoDockingInput
				onActivenessChanged={onAutoDockingActiveChanged}
				navDataChannel={navDataChannel}
				isPeerConnectionPaused={isSessionPaused}
				isVideoVisible={isVideoVisible}
			/>

			<div className="sessionContainer">
				<KeyboardNavInput
					navController={navController}
					disabled={!isKeyboardNavInputEnabled}
					onFocusChanged={onKeyboardNavInputFocusChanged}
				>
					<RemotePrimaryCamVideo
						primaryCameraState={primaryCameraState}
						sessionState={sessionState}
						onPlaybackToggle={onPrimaryVideoPlaybackToggle}
						mediaStream={primaryMediaStream}
						cameraConfigs={primaryCamerasConfig}
						canShowLoadingIndicator={!localMediaAccessError}
					/>

					{/* If the robot does not support joystick control, we render the nav-view within the keyboard input.
					This makes it possible to the pilot to be able to control the robot with keyboard
					even when the mouse cursor is on the nav-view. */}
					{!isJoystickControlSupportedByRobot &&
						renderNavViewWithSessionOptions({
							isJoystickMounted: false,
						})}

					<div className="sessionInfoContainer">
						<SessionID id={sessionInfo.uuid} />
						<ImpairedDrivingIndicator isDrivingImpaired={isDrivingImpaired} penalty={penalty} />
						<ActiveNavigationInputIndicator activeNavInput={currNavInput} />
						<RobotName name={sessionInfo.robot.name} />
					</div>

					{isJoystickControlSupportedByRobot &&
						renderNavViewWithSessionOptions({
							isJoystickMounted:
								primaryCameraState.currentPrimaryCamera === 'wide_cam' &&
								currNavInput !== 'auto-docking',
						})}
				</KeyboardNavInput>
			</div>
		</div>
	);
};

export default React.memo(reduxConnector(Session));
