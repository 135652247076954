import { useEffect, useLayoutEffect, useRef } from 'react';

/** Period (in milliseconds) for sending navigation commands to remote peer */
const SEND_COMMANDS_PERIOD_MS = 100;

/** If no new DOCK CONTINUE command is received for this long, we time out and stop looping CONTINUE command */
const DOCK_CONTINUE_COMMAND_MAX_DELAY = SEND_COMMANDS_PERIOD_MS * 10;

const AutoDockingControllerStages = [
	'IDLE',
	'STARTING',
	'DETECTING',
	'DOCKING',
	'STOPPING',
] as const;
const StatesOfStage = ['SUCCESS', 'IN_PROGRESS', 'FAILED'] as const;
/** The status of the docking controller is: at a certain stage, and the state within that stage */
export type AutoDockingStatus = {
	stage: typeof AutoDockingControllerStages[number];
	state: typeof StatesOfStage[number];
};

class _AutoDockingController {
	private loopId: ReturnType<typeof setInterval> | undefined;
	private lastReceivedCONTINUECommandTime = Number.MIN_SAFE_INTEGER;
	private lastSentCONTINUECommandTime = Number.MIN_SAFE_INTEGER;

	private dataChannel: RTCDataChannel | undefined;
	public onDataChannel(dataChannel: RTCDataChannel) {
		this.dataChannel = dataChannel;
	}

	private eventTarget = new EventTarget();

	private _status: AutoDockingStatus = { stage: 'IDLE', state: 'IN_PROGRESS' };

	public get status() {
		return this._status;
	}

	public get isIdle() {
		return this.status.stage === 'IDLE';
	}

	private onStatusChanged = (newStatus: AutoDockingStatus) => {
		const prevStatus = { ...this.status };
		this._status = { ...newStatus };
		this.eventTarget.dispatchEvent(
			new CustomEvent('status-changed', { detail: { prevStatus, status: this.status } })
		);
	};

	public start = () => {
		// dont want the remaining logic below to be executed more than once in a row, NEVER!🙅🏾‍♂️
		if (!this.isIdle) {
			console.debug('abort AutoDockingController.start() -> not idle', this.status);
			return;
		}

		this.onStatusChanged({ stage: 'STARTING', state: 'IN_PROGRESS' });
		// bind the message event handler earlier, so that we don't miss any messages
		this.dataChannel?.addEventListener('message', this.onDockingMessageFromRemotePeer);
		try {
			this.dataChannel!.send(`DOCK START ${performance.now()}`);
			this.onStatusChanged({ stage: 'STARTING', state: 'SUCCESS' });

			// reset control variables
			this.lastReceivedCONTINUECommandTime = performance.now();
			this.lastSentCONTINUECommandTime = performance.now();

			this.startDockCONTINUELoop();
		} catch (error) {
			this.onStatusChanged({ stage: 'STARTING', state: 'FAILED' });
			console.error('Failed to send DOCK START', error);
			this.stop();
		}
	};

	public stop = () => {
		if (this.isIdle) {
			console.debug('abort AutoDockingController.stop() -> already idle');
			return;
		}

		const hasDockingCompletedSuccessfully =
			this.status.stage === 'DOCKING' && this.status.state === 'SUCCESS';

		this.dataChannel?.removeEventListener('message', this.onDockingMessageFromRemotePeer);
		this.stopDockCONTINUELoop();
		try {
			if (!hasDockingCompletedSuccessfully) {
				this.dataChannel?.send(`DOCK STOP ${performance.now()}`);
			}
		} catch (error) {
			console.error('Failed to send DOCK STOP', error);
		}
	};

	public reset = () => {
		if (this.isIdle) {
			console.debug('abort AutoDockingController.reset() -> already idle');
			return;
		}

		this.onStatusChanged({ stage: 'STOPPING', state: 'IN_PROGRESS' });
		this.onStatusChanged({ stage: 'STOPPING', state: 'SUCCESS' });
		this.onStatusChanged({ stage: 'IDLE', state: 'IN_PROGRESS' });
	};

	private loopCONTINUECommand = () => {
		let isAllowedToSendCONTINUE =
			(this.status.stage === 'STARTING' && this.status.state === 'SUCCESS') ||
			(this.status.stage === 'DETECTING' && this.status.state !== 'FAILED') ||
			(this.status.stage === 'DOCKING' && this.status.state === 'IN_PROGRESS');
		if (!isAllowedToSendCONTINUE) {
			console.debug('abort AutoDockingController.loop() -> disabled', this.status);
			return;
		}

		// if we have not received a new command in a while now, abort
		if (
			performance.now() - this.lastReceivedCONTINUECommandTime >
			DOCK_CONTINUE_COMMAND_MAX_DELAY
		) {
			console.debug(`abort AutoDockingController.loop() -> no-new-commands`);
			this.stop();
			return;
		}

		// if we have not been able to send a command in a while now, abort
		if (performance.now() - this.lastSentCONTINUECommandTime > DOCK_CONTINUE_COMMAND_MAX_DELAY) {
			console.debug(`abort AutoDockingController.loop() -> unable-to-send-CONTINUE`);
			this.stop();
			return;
		}

		if (this.dataChannel?.readyState !== 'open') {
			console.debug(
				`abort AutoDockingController.loop() -> datachannel.readyState '${this.dataChannel?.readyState}'`
			);
			return;
		}

		const autoDockingMessage = `DOCK CONTINUE ${performance.now().toFixed(3)}`;

		try {
			// yay! - now we can send the auto-docking-command to the remote peer
			this.dataChannel.send(autoDockingMessage);
			this.lastSentCONTINUECommandTime = performance.now();
			console.debug('AutoDockingController.loop()', autoDockingMessage);
		} catch (error) {
			// Sorry, nope. Cant send it. 😢
			console.error('failed AutoDockingController.loop() -> error', error);
		}
	};

	/** Idempotent */
	private startDockCONTINUELoop = () => {
		if (this.isIdle) {
			console.debug('abort AutoDockingController.startDockCONTINUELoop()', this.status);
			return;
		}
		// IMPORTANT: This check ensures that we never have multiple `intervals` running at the same time!
		if (this.loopId === undefined) {
			this.loopId = setInterval(this.loopCONTINUECommand, SEND_COMMANDS_PERIOD_MS);
		}
	};

	/** Idempotent */
	private stopDockCONTINUELoop = () => {
		if (this.loopId !== undefined) {
			clearInterval(this.loopId);
			this.loopId = undefined;
		}
	};

	private onDockingMessageFromRemotePeer = (e: MessageEvent) => {
		if (e.data === undefined) return;

		console.debug(`DataReceivedFromRobot:`, e.data.toString());

		// DOCK DETECTING IN_PROGRESS
		const [_msgCategory, _stage, _state] = e.data.toString().replace('b', '').split(' ');

		const msgCategory = _msgCategory.replace("'", '');
		const stage = _stage.replace("'", '');
		const state = _state.replace("'", '');

		if (msgCategory !== 'DOCK') {
			console.error('Invalid message format', e.data.toString(), [msgCategory, stage, state]);
			return; //only handle the messages pertaining to docking
		}
		if (!AutoDockingControllerStages.includes(stage)) {
			console.warn('Got invalid docking message', { stage, state });
			return;
		}

		if (this.isIdle) {
			console.debug('abort AutoDockingController.onDockingMessageFromRemotePeer()', this.status, {
				message: e.data,
			});
			return;
		}

		this.onStatusChanged({
			stage: stage as unknown as AutoDockingStatus['stage'],
			state: state as unknown as AutoDockingStatus['state'],
		});
	};

	public onDockCONTINUE = () => {
		if (this.isIdle) {
			console.debug('abort AutoDockingController.onNavCommand()', this.status);
			return;
		}

		this.lastReceivedCONTINUECommandTime = performance.now();
		this.startDockCONTINUELoop();
	};

	public addEventListener = (event: 'status-changed', listener: (...args: any[]) => void) => {
		this.eventTarget.addEventListener(event, listener);
	};

	public removeEventListener = (event: 'status-changed', listener: (...args: any[]) => void) => {
		this.eventTarget.removeEventListener(event, listener);
	};
}

export type AutoDockingController = Pick<
	_AutoDockingController,
	// expose only a subset of public methods of _AutoDockingController, outside this module
	| 'onDockCONTINUE'
	| 'start'
	| 'stop'
	| 'addEventListener'
	| 'removeEventListener'
	| 'status'
	| 'reset'
>;

export default function useAutoDockingController(props: {
	isPeerConnectionPaused: boolean;
	datachannel?: RTCDataChannel;
	/** Pass in True, if the video can be seen by the user, and there are no overlays, modals etc, obscuring the view */
	isVideoVisible: boolean;
	/** Penalty to be applied to speed  */
	penalty?: number;
}) {
	const { isPeerConnectionPaused, datachannel, penalty, isVideoVisible } = props;
	// a ref, because we dont ever change the created instance
	const autoDockingController = useRef(new _AutoDockingController());

	useLayoutEffect(() => {
		if (datachannel) autoDockingController.current.onDataChannel(datachannel);
	}, [datachannel]);

	useLayoutEffect(() => {
		const shouldDisableAutoDocking = isPeerConnectionPaused || !isVideoVisible;
		if (shouldDisableAutoDocking) {
			autoDockingController.current.stop();
		}
	}, [isPeerConnectionPaused, isVideoVisible]);

	useEffect(() => {
		//
	}, [penalty]);

	// Deactivate the AutoDockingController when the implementing component
	// 	is about to be unmounted.
	useLayoutEffect(() => {
		console.debug(`useAutoDockingController -> mounted`);
		const controller = autoDockingController.current;
		return () => {
			console.debug(`useAutoDockingController -> unmounted`);
			controller.stop(); // this is a no-op if it has been terminated already
		};
	}, []);

	return autoDockingController.current as AutoDockingController;
}
