import React from 'react';
import './index.scss';
import { LocalSessionInfo } from 'types';

type Props = {
	isVisible: boolean;
	isSessionStarting: boolean;
	isSessionInitializing: boolean;
	robot: LocalSessionInfo['robot'];
	onClickEndSession: () => void;
};

export default function RetryingSessionOverlay({
	onClickEndSession,
	isVisible,
	isSessionStarting,
	isSessionInitializing,
	robot,
}: Props) {
	return isVisible ? (
		<div
			className={`retrying-session-container ${
				isSessionInitializing ? 'retrying-session-container-initializing' : ''
			}`}
		>
			{/* <div className="loading-indicator" /> */}

			<div className="title">{isSessionInitializing ? 'Connecting' : 'Reconnecting'}...</div>
			<div className={`message ${!isSessionInitializing && isSessionStarting ? 'hidden' : ''}`}>
				{isSessionInitializing
					? `Your session is connecting to ${robot.name}`
					: 'Your session will be automatically resumed'}
			</div>
			{!isSessionInitializing ? (
				<div
					className={`end-session-button  ${isSessionStarting ? 'hidden' : ''}`}
					onClick={() => onClickEndSession()}
				>
					End session
				</div>
			) : null}
		</div>
	) : null;
}
