import React, { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';
import NavigationVideo from '../../videos/navigationVideo';
import SessionOptions from 'components/sessionOptions';
import useDelayedShowOrHide from 'utils/useDelayedShowOrHide';
import RobotFeatures from 'components/robotFeatures';
import { useIdleTimer } from 'react-idle-timer';
import { useTypedSelector } from 'reducers';
import './index.scss';

type NavVideoProps = ComponentProps<typeof NavigationVideo>;
type SessionOptionsProps = ComponentProps<typeof SessionOptions>;
type RobotFeaturesProps = ComponentProps<typeof RobotFeatures>;

type Props = { isNavigating: boolean; navCameraRotation: number } & Omit<
	NavVideoProps,
	'isNavCameraVertical'
> &
	Omit<SessionOptionsProps, 'isNavCameraVertical'>;

export default function NavViewWithSessionOptions(props: Props) {
	const [isIdle, setIsIdle] = useState(false);

	const onIdle = useCallback(() => {
		setIsIdle(true);
	}, []);
	const onActive = useCallback(() => {
		setIsIdle(false);
	}, []);
	const idleTimer = useIdleTimer({ onActive, onIdle, timeout: 5 * 1000 });

	const isInDrivingMode = useTypedSelector((state) => state.sessionState.drivingMode);
	useEffect(() => {
		if (isInDrivingMode) {
			idleTimer.pause();
		} else {
			idleTimer.reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isInDrivingMode]);

	const isSessionOptionsVisible = useDelayedShowOrHide(!props.isNavigating, {
		showDelayMs: 1000,
		hideDelayMs: 0,
	});

	const isNavCameraVertical: boolean = useMemo(
		() => Math.abs(props.navCameraRotation) % 180 === 90,
		[props.navCameraRotation]
	);

	return !isIdle ? (
		<div className={isInDrivingMode ? 'navViewContainer' : 'navViewContainerDriving'}>
			{isSessionOptionsVisible ? (
				<RobotFeatures {...({ ...props, isNavCameraVertical } as RobotFeaturesProps)} />
			) : null}
			<NavigationVideo {...(props as NavVideoProps)} />
			{isSessionOptionsVisible ? (
				<SessionOptions {...({ ...props, isNavCameraVertical } as SessionOptionsProps)} />
			) : null}
		</div>
	) : null;
}
