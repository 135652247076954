import {
	SET_DATA_CHANNEL,
	SET_BATTERY_STATUS,
	SET_FULL_SCREEN_STATUS,
	SET_LOCAL_VOICE_VOLUME,
	SET_NAV_SPEED,
	SET_HIDE_NAV_OPTIONS_STATUS,
	SET_DRIVING_MODE_STATUS,
	SET_NAV_CAMERA_HEIGHT,
	SET_REMOTE_VOICE_VOLUME,
	SET_ROBOT_INFO,
} from 'actions/types';

export const DEFAULT_NAV_CAMERA_HEIGHT = 250;

type SessionStateModel = {
	dataChannel1: any;
	batteryStatus: boolean;
	localVoiceStatus: boolean;
	fullScreenStatus: boolean;
	localVoiceVolume: string;
	remoteVoiceVolume: string;
	navSpeed: string;
	hideNavOptionsStatus: boolean;
	drivingMode: boolean;
	navCameraHeight: number;
	robot: { id: string; name: string; serialNumber: string };
};

type SessionActionsModel = {
	type: string;
	payload: SessionStateModel;
};

const Initial_State: SessionStateModel = {
	dataChannel1: null,
	batteryStatus: false,
	localVoiceStatus: false,
	fullScreenStatus: false,
	localVoiceVolume: '19',
	remoteVoiceVolume: '50',
	navSpeed: '50',
	hideNavOptionsStatus: true,
	drivingMode: true,
	navCameraHeight: DEFAULT_NAV_CAMERA_HEIGHT,
	robot: { id: '', name: '', serialNumber: '' },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = Initial_State, action: SessionActionsModel) => {
	switch (action.type) {
		case SET_DRIVING_MODE_STATUS:
			return { ...state, drivingMode: action.payload.drivingMode };
		case SET_NAV_CAMERA_HEIGHT:
			return { ...state, navCameraHeight: action.payload.navCameraHeight };
		case SET_DATA_CHANNEL:
			return { ...state, dataChannel1: action.payload.dataChannel1 };
		case SET_BATTERY_STATUS:
			return { ...state, batteryStatus: action.payload.batteryStatus };
		case SET_FULL_SCREEN_STATUS:
			return { ...state, fullScreenStatus: action.payload.fullScreenStatus };
		case SET_HIDE_NAV_OPTIONS_STATUS:
			return {
				...state,
				hideNavOptionsStatus: action.payload.hideNavOptionsStatus,
			};
		case SET_REMOTE_VOICE_VOLUME:
			return { ...state, remoteVoiceVolume: action.payload.remoteVoiceVolume };
		case SET_LOCAL_VOICE_VOLUME:
			return { ...state, localVoiceVolume: action.payload.localVoiceVolume };
		case SET_NAV_SPEED:
			return { ...state, navSpeed: action.payload.navSpeed };
		case SET_ROBOT_INFO:
			return { ...state, robot: action.payload.robot };
		default:
			return state;
	}
};
