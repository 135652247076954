import React, { useState, useMemo } from 'react';
import useDelayedShowOrHide from 'utils/useDelayedShowOrHide';
import './index.scss';

import genericAlertIcon from 'images/alert.svg';
import redAlertIcon from 'images/red-alert.svg';
import whiteCloseChevronIcon from 'images/white-close-chevron.svg';

type Props = {
	isDrivingImpaired: boolean;
	penalty: number;
};

const showOrHideConfig = {
	showDelayMs: 0, // show the indicator immediately when driving is impaired
	hideDelayMs: 1000, // hide the indicator after a delay of X ms, when driving is no longer impaired
};

export default function ImpairedDrivingIndicator({ isDrivingImpaired, penalty }: Props) {
	const [isMinimized, setIsMinimized] = useState(true);
	const isVisible = useDelayedShowOrHide(isDrivingImpaired, showOrHideConfig);
	const icon = useMemo(() => (penalty === 1 ? redAlertIcon : genericAlertIcon), [penalty]);

	return isMinimized ? (
		<div
			className={isVisible ? 'drivingImpairmentCircle' : 'displayNone'}
			onClick={() => setIsMinimized(false)}
		>
			<div className="warningIconWrapper">
				<img src={icon} alt="" />
			</div>
		</div>
	) : (
		<div className={isVisible ? 'drivingImpairmentContainer' : 'displayNone'}>
			<div className="contentContainer">
				<div className="headingContainer">
					<div className="warningIconWrapper">
						<img src={icon} alt="" />
					</div>
					<div className="impairedHeading">Whoops!</div>
				</div>
				<div className="textSessionConnectionContainer">
					There seems to be a problem with your Network Connection.
					<br />
					Driving speed is now limited.
				</div>
			</div>
			<img
				className="closeIconWrapper"
				onClick={() => setIsMinimized(true)}
				src={whiteCloseChevronIcon}
				alt=""
			/>
		</div>
	);
}
