import { useCallback, useRef, useState } from 'react';

/** The various overlays (covers the entire session screen)
 * that may be shown at any point in time during a session */
type ISessionOverlay =
	| 'LocalMediaError'
	| 'EndOrPauseSessionConfirmation' // overlay where we ask the user to confirm ending the session
	| 'SessionNetworkFailure' // overlay shown when the underlying peer connection fails
	| 'UnavailableRobot' // overlay shown when unable to establish session because robot is unavailable
	| 'NoRemoteVideo' // overlay shown when we dont ever get any video from the robot at the start of a session
	| 'SessionRetrying'; // overlay shown when we are retrying a session
// | 'PermissionError'; // overlay shown when we are unable to get access to the user's media devices at the start of a session

function useSessionOverlay() {
	/**
	 * Bigger number means higher priority.
	 * If a higher priority overlay is currently set, a lower one cannot override it.
	 */
	const overlaysPriority = useRef<Record<ISessionOverlay, number>>({
		EndOrPauseSessionConfirmation: 0,
		SessionRetrying: 1,
		SessionNetworkFailure: 2,
		UnavailableRobot: 3,
		NoRemoteVideo: 4,
		LocalMediaError: 5,
	}).current;

	const [currentOverlay, setOverlay] = useState<ISessionOverlay | null>(null);

	const showOverlay = useCallback(
		(newOverlay: ISessionOverlay) => {
			setOverlay((currentOverlay) => {
				if (currentOverlay === null) return newOverlay;
				else if (overlaysPriority[currentOverlay] > overlaysPriority[newOverlay])
					return currentOverlay;
				else return newOverlay;
			});
		},
		[overlaysPriority]
	);

	const hideOverlay = useCallback(() => setOverlay(null), []);

	return { currentOverlay, showOverlay, hideOverlay };
}

export default useSessionOverlay;
