import {
	Capabilities,
	ICEPayload,
	ISignalingClient,
	RobotStatus,
	SDPPayload,
	SIGNALING_CLIENT_KEEPALIVE_INTERVAL,
} from './types';
import { io } from 'socket.io-client';
import type { Socket } from 'socket.io-client';
import { LocalSessionInfo } from 'types';

/** Events that may be sent and/or received by the socketio client */
enum SignalingEvent {
	Ready = 'ready',
	Keepalive = 'keepalive',
	ICECandidate = 'ice-candidate',
	ICERestartRequest = 'ice-restart-requested',
	SessionRetryRequest = 'session-retry-requested',
	SDP = 'sdp',
	Hangup = 'hangup',
	RobotStatus = 'robot-status',
	Capabilities = 'capabilities',
	/** Received when the remote peer is now ready to retry the session */
	AvailableForRetry = 'available-for-retry',
}

type HangupEventPayload = { reason: string; willRetry: boolean };

export const DEFAULT_ROBOT_STATUS: RobotStatus = {
	battery: {
		level: '100',
		charging: false,
	},
	network: {
		quality: 100,
		ssid: '',
	},
};

/** A signaling client with socketIO as the mode of transport */
export default class SocketIOSignalingClient implements ISignalingClient {
	private isClosed: boolean = false;

	private incomingKeepaliveTimeoutId: ReturnType<typeof setTimeout> | null = null;
	private outgoingKeepaliveLoopId: ReturnType<typeof setInterval> | null = null;

	onRemoteSDP: ((data: SDPPayload) => void) | null = null;
	onRemoteICECandidate: ((payload: ICEPayload) => void) | null = null;
	onRemoteHangUp: (() => void) | null = null;
	onKeepaliveTimeout: (() => void) | null = null;
	onReconnect: (() => void) | null;
	onRemoteWillRetry: (() => void) | null;
	onRemoteReadyToRetry: (() => void) | null;
	onRemoteRobotStatus: ((payload: RobotStatus) => void) | null = null;
	onRemoteRobotCapabilities: ((payload: Capabilities) => void) | null = null;

	private socketIOClient: Socket;

	constructor(
		public readonly sessionInfo: Omit<LocalSessionInfo, 'signaling'> & {
			signaling: NonNullable<LocalSessionInfo['signaling']>;
		}
	) {
		this.socketIOClient = io(sessionInfo.signaling.url, {
			auth: { token: { accessToken: sessionInfo.signaling.token } },
			// See https://socket.io/docs/v4/client-options/ for more information about the options used below
			autoConnect: false, // no thank you, we will connect manually when we are ready
			forceNew: true, // create a completely new IO engine; dont reuse an existing one.
		})
			.onAny(this.onSocketIOEvent)
			.once('connect', () => {
				console.log('Connected to signaling server');

				if (sessionInfo.robotStatus) {
					this.onRemoteRobotStatus?.({
						battery: sessionInfo.robotStatus.battery ?? DEFAULT_ROBOT_STATUS.battery,
						network: sessionInfo.robotStatus.network ?? DEFAULT_ROBOT_STATUS.network,
					});
				}
			})
			.on('disconnect', () => {
				console.log('Disconnected from signaling server');
			})
			.on('connect_error', (err) => {
				console.error('Error connecting to the signaling server', err);
			});
	}

	private emitSocketIOEvent = (
		event: SignalingEvent,
		data: Record<string, unknown> & { to?: never } = {},
		isVolatile: boolean = false
	) => {
		if (this.isClosed) {
			console.error('Will not emit event, signaling client is closed', {
				event,
			});
			return;
		}

		const sender = isVolatile ? this.socketIOClient.volatile : this.socketIOClient;
		sender.emit(event, { ...data });
	};

	private onSocketIOEvent = (event: SignalingEvent, data: Record<string, unknown>) => {
		if (this.isClosed) {
			console.warn('Will not handle incoming event, signaling client is closed', { event });
			return;
		}

		switch (event) {
			case SignalingEvent.SDP:
				return this.onRemoteSDP?.(data as SDPPayload);
			case SignalingEvent.ICECandidate:
				return this.onRemoteICECandidate?.(data as ICEPayload);
			case SignalingEvent.Hangup:
				return this._onRemoteHangup?.(data as HangupEventPayload);
			case SignalingEvent.RobotStatus:
				return this.onRemoteRobotStatus?.(data as RobotStatus);
			case SignalingEvent.Capabilities:
				return this.onRemoteRobotCapabilities?.(data as Capabilities);
			case SignalingEvent.Keepalive:
				return this.resetIncomingKeepaliveTimeout();
			case SignalingEvent.AvailableForRetry:
				return this.onRemoteReadyToRetry?.();
			default:
				console.warn('Unexpected socketIO event', event);
				return;
		}
	};

	private _onRemoteHangup = (data: HangupEventPayload) => {
		const { reason, willRetry } = data;
		console.info('Remote peer hung up', { reason, willRetry });
		if (willRetry) {
			this.onRemoteWillRetry?.();
		} else {
			this.onRemoteHangUp?.();
		}
	};

	start = () => {
		if (this.isClosed) {
			console.warn('Cannot call signalingClient.start(), already closed');
			return;
		}

		this.socketIOClient.connect(); // this is async, but that's okay
		this.startOutgoingKeepaliveLoop();

		// TODO: Start incoming keepalive timeout

		// We will wait for the remote peer to send us the first keepalive message,
		//  before we start counting down the keepalive timeout.
		// This is to ensure that the remote peer supports keepalive.
		// this.resetIncomingKeepaliveTimeout();
	};

	sendSDPToPeer = (data: SDPPayload) => this.emitSocketIOEvent(SignalingEvent.SDP, data);

	sendICECandidateToPeer = (data: ICEPayload) =>
		this.emitSocketIOEvent(SignalingEvent.ICECandidate, data);

	sendICERestartRequest = () => this.emitSocketIOEvent(SignalingEvent.ICERestartRequest);

	sendSessionRetryRequest = (reason?: string) => {
		console.log(`Sending session-retry request. Reason: ${reason}`);
		this.emitSocketIOEvent(SignalingEvent.SessionRetryRequest);
	};

	sendReadyForInitialSDPOffer = () => this.emitSocketIOEvent(SignalingEvent.Ready);

	sendReadyForRetry = () => this.emitSocketIOEvent(SignalingEvent.Ready);

	sendHangup = () => this.emitSocketIOEvent(SignalingEvent.Hangup);

	close = () => {
		if (this.isClosed) {
			return;
		}

		this.isClosed = true;
		this.socketIOClient.close();
		this.stopIncomingKeepaliveTimeout();
		this.stopOutgoingKeepaliveLoop();
	};

	private startOutgoingKeepaliveLoop = () => {
		this.stopOutgoingKeepaliveLoop();

		this.outgoingKeepaliveLoopId = setInterval(
			() => this.emitSocketIOEvent(SignalingEvent.Keepalive, {}, true),
			SIGNALING_CLIENT_KEEPALIVE_INTERVAL
		);
	};

	private stopOutgoingKeepaliveLoop = () => {
		if (this.outgoingKeepaliveLoopId !== null) {
			clearInterval(this.outgoingKeepaliveLoopId);
		}
		this.outgoingKeepaliveLoopId = null;
	};

	private resetIncomingKeepaliveTimeout = () => {
		this.stopIncomingKeepaliveTimeout();

		this.incomingKeepaliveTimeoutId = setTimeout(() => {
			this.onKeepaliveTimeout?.();
		}, SIGNALING_CLIENT_KEEPALIVE_INTERVAL);
	};

	private stopIncomingKeepaliveTimeout = () => {
		if (this.incomingKeepaliveTimeoutId !== null) {
			clearTimeout(this.incomingKeepaliveTimeoutId);
		}
		this.incomingKeepaliveTimeoutId = null;
	};
}
